
































import { defineComponent } from "@vue/composition-api"
import AvatarList from "@/components/AvatarList.vue"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import Icon from "@/components/Icon.vue"
import { TypedComponentProps } from "@/vue-props-types"
import { MeetingListItemProps } from "./types"

const propsOptions = {
  parentId: {
    type: String,
    required: true,
  },
  meeting: {
    type: Object,
    required: true,
  },
}

export default defineComponent<
  TypedComponentProps<MeetingListItemProps, typeof propsOptions>
>({
  props: propsOptions,
  components: {
    AvatarList,
    I18nFormattedDateTime,
    Icon,
  },
})
